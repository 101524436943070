import React from 'react';

import listening from '../assets/img/listening.png';

const ListeningPing = () => {
  return (
    <>
      <h4 className='mb-6 italic'>IN RIPRODUZIONE</h4>

      <div className='relative w-[80%] mx-auto'>

        <img
          className='absolute animate-ping-slow w-full opacity-40'
          alt="In riproduzione..."
          src={listening}
        />

        <img
          className='absolute w-full'
          alt="In riproduzione..."
          src={listening}
        />
      </div>
    </>
  );
};

export default ListeningPing;