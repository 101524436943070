import React, { useState } from 'react';

import Header from './Components/Header';

import Intro from './Components/Intro';
import Listening from './Components/Listening';

const MainPage = () => {

  const [entered, setEntered] = useState(false);

  return (

    <div className='flex flex-col justify-center items-center text-mf-sand h-full mx-4'>

      <Header />

      <div
        className='flex flex-col justify-around items-center flex-1 border border-mf-sand-900 w-full m-4 p-4 rounded shadow bg-mf-dark-500'
      >

        {!entered && <Intro onEnter={() => setEntered(true)} />}

        {entered && <Listening />}

      </div>

    </div>

  );
};

export default MainPage;