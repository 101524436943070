export const AUDIO_FILES = [
  {
    label: 'Xilofono [3 s]',
    url: '/audio/1_Xilofono.mp3'
  },
  {
    label: 'Violino [7 s]',
    url: '/audio/2_Violino.mp3'
  },
  {
    label: 'Voce [13 s]',
    url: '/audio/3_Voce.mp3'
  },
  {
    label: 'Violoncello [13 s]',
    url: '/audio/4_Violocello.mp3'
  },
  {
    label: 'Čajkovskij - Sinfonia n.4 in fa minore [22 s]',
    url: '/audio/5_Sinfonia.mp3'
  },
  {
    label: 'Mozart - Don Giovanni [30 s]',
    url: '/audio/6_Mozart.mp3'
  }
];