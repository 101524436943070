import React, { useEffect } from 'react';

import { AUDIO_FILES } from '../utils/constants';

const SoundSelect = ({ disabled, selectedSoundUrl, onChange }) => {

  useEffect(() => onChange(AUDIO_FILES[0].url), []);

  const textColor = disabled ? 'text-mf-dark' : 'text-mf-sand';

  return (
    <div className='text-center my-6'>

      <h4 className='font-bold pb-2'>SCEGLI UN SUONO</h4>

      <select
        disabled={disabled}
        className={`select bg-mf-dark ${textColor} border-mf-sand w-[90%]`}
        value={selectedSoundUrl}
        onChange={e => onChange(e.target.value)}
      >
        {AUDIO_FILES.map(audio => (
          <option
            value={audio.url}
            key={audio.url}
          >
            {audio.label}
          </option>
        ))}

      </select>

      <button>

      </button>
    </div>
  );
};

export default SoundSelect;