import phone from './assets/img/phone.svg';

import MainPage from './MainPage';

const App = () => {
  return (
    <>
      <div className="hidden md:flex flex-col justify-center items-center w-full h-[100vh]">

        <h1 className="text-mf-sand text-2xl mb-10">
          Quest'app è progettata per dispositivi mobili
        </h1>

        <img src={phone} className="w-[200px]" alt="Phone" />

      </div>

      <div className='flex md:hidden w-full h-[100vh] flex-col'>
        <MainPage />
      </div>
    </>
  );
};

export default App;
