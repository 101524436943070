import React from 'react';

import maggioLogo from '../assets/img/maggio_logo.png';

const Header = () => {
  return (
    <nav className='w-full flex items-center justify-center pt-4'>
      <img
        className="w-[250px]"
        alt="Maggio Musicale Fiorentino"
        src={maggioLogo}
      />
    </nav>
  )
}

export default Header