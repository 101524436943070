import React from 'react';

import Footer from './Footer';

const Intro = ({ onEnter }) => (
  <>
    <h3 className='text-[1.5rem]'>
      PUNTO D'ASCOLTO 5
    </h3>

    <button
      className='rounded border px-6 py-4 cursor-pointer hover:opacity-50 text-[1.3rem]'
      onClick={onEnter}
    >
      ENTRA
    </button>

    <Footer />
  </>
);

export default Intro;