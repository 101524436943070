import React, { useCallback, useState } from 'react';

import ReactHowler from 'react-howler';
import ListeningPing from './ListeningPing';

import SoundSelect from './SoundSelect';



const Listening = () => {

  const [selectedSoundUrl, setSelectedSoundUrl] = useState();

  const [playing, setPlaying] = useState(false);

  const onSoundChange = useCallback((url) => {
    setSelectedSoundUrl(url);
  }, []);

  return (
    <>
      <div className='text-center h-full'>

        <h4 className='text-[1rem]'>
          PUNTO D'ASCOLTO 5
        </h4>

        <span className='w-[50%] border-b-2 inline-block mb-2 pb-2' />

        <SoundSelect
          disabled={playing}
          selectedSoundUrl={selectedSoundUrl}
          onChange={onSoundChange}
        />

        {selectedSoundUrl &&
          <>
            <ReactHowler
              src={selectedSoundUrl}
              playing={playing}
              onEnd={() => setPlaying(false)}
            />
          </>
        }

        {(selectedSoundUrl && !playing) && (
          <button
            className='rounded border px-6 py-4 cursor-pointer hover:opacity-50 text-[1rem] mt-10'
            onClick={() => setPlaying(!playing)}
          >
            RIPRODUCI
          </button>
        )}

        {(selectedSoundUrl && playing) && <ListeningPing />}

      </div>

      <span className='opacity-70'>powered by <a href="https://mezzoforte.design" target="_blank" rel="noopener noreferrer">mezzo forte</a></span>
    </>
  )
}

export default Listening