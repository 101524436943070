import React from 'react';

import mf from '../assets/img/logo_beige_notype.png';

const Footer = () => {
  return (
    <footer className='text-mf-beige flex flex-col justify-center items-center'>
      <img src={mf} className="w-[110px] mb-2" alt="Mezzo Forte" />
      <span>powered by <a href="https://mezzoforte.design" target="_blank" rel="noopener noreferrer">mezzo forte</a></span>
    </footer>
  )
}

export default Footer